import React, { useState, useEffect } from "react";
import { BrowserRouter as Router } from 'react-router-dom';

import Chat from "components/chat/chat";

import { checkUserOnline } from "components/app/school/actions/checkUserOnline";

import { HeadComponent } from "components/head";
import { appServices } from "api/schoolApp/services";
import { SchoolAppStyle } from "GlobalStyle";


const initialUserState = {
  email: "",
  full_name: "",
  id: 0,
  uid:'',
  phone_number: "",
  gender: ""
}
// const initalLayoutState = {
//   "type":"",
//   "data": initialUserState,
//   "isAuthenticated":true,
// };

const App = () => {
  
    const [user, setUser] = useState(initialUserState);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);


    const fetchUser = () => {
        setLoading(true);
        appServices.getUser().then((result) => {
            setUser({...initialUserState, ...result.data});
            checkUserOnline(result.data.uid);
            console.log(result.data);
            setLoading(false); 
        }).catch(error => {
            setLoading(false);
            setError(true);
            console.log(error);
            setUser(initialUserState);
        })
    };
    useEffect(() => {
      fetchUser();
    }, []);
    useEffect(() => {
      if(user.id){
        const intval = setInterval(() => {
            checkUserOnline(user.uid);
        }, 1000 * 60)
        return () => {
          clearInterval(intval);
        }
      }
  }, [user])
  const href = window.location.href.split('//')[1].split('/')[1].split('?');
  return (
    <>
      <Router>  
        <HeadComponent title="Voskool" />
        {!loading && (
          <>
          {!error ? (
            <>
            <Chat user_type="dev" user={user} user_id={user.id} assistant_id={href[0]} />
            </>
          ) : (
            <>
            <h1>404 Page Not Found</h1>
            </>
          )}
          </>
        )}
        {/* <Voskool state={user} />
        <SchoolWebApp state={user} /> */}
        <SchoolAppStyle />
      </Router>  
    </>
  );
}

export default App;
