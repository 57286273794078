import styled from "styled-components";

export const Wrapper = styled.div`
    padding: 10px;
    border-top-left-radius:35px;
    border-top-right-radius: 35px;
    transform: translateY(-30px);
    min-height: 100px;
`;

export const Content = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
    padding: 5px;
    margin: 8px 0;
    transition: .3s ease;
    cursor: pointer;
    border-radius: 10px;

    &:not(.active):hover{
        background: rgba(0,0,0,0.05);
        box-shadow: 0 0 3px 5px rgba(0,0,0,0.01);
    }

    

    .img-cont{
        padding: 0 7px;
        position: relative;
        .stat-cont{
            position: absolute;
            right: 9px;
            bottom: 5px;
            .online{
                background: green;
            }
            .offline{
                background: red;
            }
            span{
                width: 8px;
                display: block;
                height: 8px;
                border-radius: 50%;
            }
        }
        img{
            width: 40px;
            height: 40px;
            border-radius: 50%;
        }
    }
    .text-cont{
        width: 60%;
    }
    .num-cont{
        span{
            padding: 2px 5px;
            background: rgb(235, 43, 20);
            border-radius: 50%;
            color: #ddd;
        }
    }
`;