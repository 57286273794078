import { sortArrayByNumber } from "./Sort";

export const updateArray = (arr=[], num=0, item, key="") => {
    var k = key ? key : 'num';
    const check = arr.find((it) => {
        return it[k] === num
    })
    if(check){
        const filterItem = arr.filter((it) => {
            return it[k] !== num;
        })
        return sortArrayByNumber([...filterItem, {...item, [k]:num}])
    }
    else{
        return sortArrayByNumber([...arr, {...item, [k]:num}]);
    }
}

export const updateArrayWithArray = (arr=[], filterArr=[], key="", filterKey="", is_add_prev_object=false, is_start_with_arr=true) => {
    if(arr.length > filterArr.length || arr.length === filterArr.length){
        let data = arr;
        if(filterArr.length){
            for(let i = arr.length; i >=0; i--){
                for(let j = 0; j < filterArr.length; j++){
                    if(typeof arr[i] === "object" && typeof filterArr[j] === "object"){
                        if(arr[i][key] === filterArr[j][filterKey?filterKey:key]){
                            data.splice(i, 1, is_add_prev_object ? (is_start_with_arr ? {...arr[i], ...filterArr[j]} : {...filterArr[j], ...arr[i]}) : {...filterArr[j]}) //splice(position, numberOfItemsToAddAfterDeletingTheDataInThePosition, itemToUpdateWith)
                        }
                    }
                    else if(arr[i] === filterArr[j]){
                        data.splice(i, 1, filterArr[j]);
                    }
                }
            }
        }
        return data;
    }
    return [];
}

export const updateinnerArray = (arr=[],  arr_num=0, inner_array_name='', num=0, item) => {
    console.log(item);
    const check = arr.find((it) => {
        return it.num === arr_num
    })
    if(check){
        const check = arr[arr_num][inner_array_name].find((it) => {
            return it.num === num
        })
        if(check){
            const filterArray = arr.filter((it) => {
                return it.num !== arr_num;
            })
            const filterItem = arr[arr_num][inner_array_name].filter((it) => {
                return it.num !== num;
            })
            const filterArray2 = arr.filter((it) => {
                return it.num === arr_num;
            })
            const item_array = sortArrayByNumber([...filterItem, {...item, num}]);
            const all_item_array = {...filterArray2[0], [inner_array_name]:item_array};
            return sortArrayByNumber([...filterArray, {...all_item_array}]);
        }
        else{
            return arr;
        }
    } else{
        return arr;
    }
}