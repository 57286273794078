export const filterArray = (arr=[], key="", filterBy) => {
    return arr.filter((item) => item[key] === filterBy);
}
export const filterArrayByArray = (arr=[], filterArr=[], key="", filterKey='') => {
    let data = [];
    for(let i = 0; i < arr.length; i++){
        const filt = filterArr.filter((it) => it[filterKey] === arr[i][key]);
        if(filt.length < 1){
            data.push(arr[i]);
        } 
    }
    return data;
}
export const filterInnerArray = (arr=[], inner_arr_name="", filterArrKey="", filterBy) => {
    let data = [];
    for(let i = 0; i < arr.length; i++){
        data.push({...arr[i], [inner_arr_name]:filterArray(arr[i][inner_arr_name], filterArrKey, filterBy)})
    }
    return data;
}
export const repositionFilteredInnerArrayToFirstArray = (arr=[], inner_arr_name="") => {
    let data = [];
    for(let i = 0; i < arr.length; i++){
        data.push({...arr[i], ...arr[i][inner_arr_name][0]})
    }
    return data;
}
export const filterObjectByKey = (obj={}, filter={}) => {
    let data = {};
    for (let k in obj){
        const check = Object.keys(filter).filter((it) => it === k);
        if(check.length > 0){
            data = {...data, [k]:obj[k]};
        }
    }
    console.log(data)
    return data;
}