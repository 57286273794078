import axios from 'axios';
import { BACKEND_URL, API } from 'actions/url';
const token = localStorage.getItem("access") ? JSON.parse(localStorage.getItem("access")) : '';

const href = window.location.href.split('//')[1].split('/')[1].split('?');
export default axios.create({
    baseURL: `${BACKEND_URL}${API}`,
    headers: {
        "Content-Type":"application/json",
        Authorization: `${href[0]}`,
    }
})

export const httpChat = '';


export const http_file = axios.create({
    baseURL:`${BACKEND_URL}${API}`,
    headers: {
        'Content-Type':'multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW',
        Authorization: `JWT ${token.access}`,
        School: href[3],
    }
})