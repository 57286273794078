import React from "react";

import { Wrapper, Content, Container } from "./style";

export const FlexWrapper = ({children}) => {
    return(
        <Wrapper>
            {children}
        </Wrapper>
    )
}

const Flex = ({children, classname="", style}) => {
    return(
        <Content className={`${classname}`} style={style} >
            <Container>
                {children}
            </Container>
        </Content>
    )
}

export default Flex;
