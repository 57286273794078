import styled from "styled-components";

export const OuterWrapper = styled.div`
    position: relative;
    height: 78vh;
    overflow-y: auto;
    width: 100%;
    border-bottom-right-radius: 35px;
    border-bottom-left-radius: 35px;
    transform: translateY(15px);
    z-index: 1000;
    .y-50px{
        transform: translateY(-50px);
    }
    @media (max-width: 780px){
        height: 81vh;
    }
`;
export const Wrapper = styled.div`
    padding: 13px 10px 10px 18px;
    width: 100%;
    margin-bottom: 20px;
`;

export const Content = styled.div`
    margin-top: 5px;
    margin-bottom: 5px;
    width: 100%;
    position: relative;
    .border-1px-black{
        border: 1px solid #555;
    }
    .br{
        border-radius: 10px;
    }
    .btnn-wrapper{
        .disp-block{
            padding: 1px;
            padding-left: 6px;

            span{
                cursor: default;
            }
        }
    }
    .anim-icon{
        position: relative;
    }
    span.anim-up-down{
        transition: .3s ease;
       
    }
    span.app-theme.anim-up-down:before{
        background: var(--appTheme);
    }
    span.app-bg-color.anim-up-down:before{
        background: var(--appBgColor);
    }
    span.anim-up-down:before{
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        margin-right: 10px;
        height: 100%;
        width: 100%;
        animation: animUpDown 1.5s steps(12) infinite;
        /* -webkit-animation: animUpDown 1s infinite;
        -moz-animation: animUpDown 1s infinite; */
        /* animation-delay: attr(timer, 2px); */

        @keyframes animUpDown{
           
            40%, 60%{
                left: 100%;
            }
            100%{
                left: 10%;
            }
        }
    }
    
    .img-cont{
        position: absolute;
        
        img{
            width: 20px;
            height: 20px;
            border-radius: 50%;
        }
    }
    .img-cont.left{
        left: 0;
        bottom:0;
    }
    .img-cont.right{
        right: 0;
        bottom:0;
    }
    .icon-cont{
        position: absolute;

        
    }
    .icon-cont.left{
        right: 7px;
        bottom:0px;
    }
    .icon-cont.right{
        right: 0;
        bottom:0px;
    }
    .icon-cont.right-time{
        right: 12px;
        bottom:0px;
    }
    span{
        letter-spacing: .4px;
    }
    .cont{
        display: inline-block;
        transition: .3s;
        padding: 10px 13px;
        padding-top: 7px;
        padding-bottom: 14px;
        position: relative;
    }
    .cont.left{
        margin-left: 23px;
        border-bottom-right-radius: 15px;
        border-top-right-radius: 15px;
        border-top-left-radius: 15px;
    }
    .cont.right{
        margin-right: 23px;
        border-bottom-left-radius: 15px;
        border-top-right-radius: 15px;
        border-top-left-radius: 15px;
    }
`;