import http  from "api/http_setup";


export const appServices = {
  getUser: () => {
    return  http.get(`chat/va/account/get`);
  },
  checkUserOnline: (id=0, data={is_online: new Date().getTime() + 300000}) => {
    return  http.put(`account/va/update_online/${id}`, data);
  }
}