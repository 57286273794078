import React, {Fragment} from "react";

import { OuterWrapper, Wrapper, Content } from "./style";

export const MessageUIWrapper = ({children,outer_classname=""}) => {
    return(
        <OuterWrapper className={`${outer_classname}`}>
            <Wrapper >
                {children}
            </Wrapper>
        </OuterWrapper>
    )
}

const MessageUI = ({text="", time="", is_send=false, bg_color="", img_src="", img_alt="ico", is_loading=false, is_error=false, is_seen=true, is_loading_text=false, is_typing=false, btn_pallete=[]}) => {
    const msgTypeAnim = (title="") => {
        const name = title.toString();
        return [...name].map((letter, i) => {
            // const exc = ['', '.']
            return(
                <span key={i} className={`anim-up-down fw-700 font-small ${is_send ? bg_color : 'app-theme'}`} timer={`${i}px`} >{letter}</span>
            )
        })
    }
    return(
        <Content >
            {is_typing ? (
                <div className={`wrapper ${is_send ? 'disp-block align-right' : ''}`}>
                    <div className={`img-cont ${is_send ? 'right' : 'left'}`}>
                        <img src={img_src} alt={img_alt} className="font-very-super-small"/>
                    </div>
                    <div className={`cont ${is_send ? `${bg_color} right` : 'app-theme left'}`}>
                        <span className={`fw-500 font-small anim-icon ${is_send ? 'app-bg-text-color  ' : 'app-text-color '}`}>{msgTypeAnim('...')}</span>
                    </div>
                </div>
            ) : (
                <div className={`wrapper ${is_send ? 'disp-block align-right' : ''}`}>
                    <div className={`img-cont ${is_send ? 'right' : 'left'}`}>
                        <img src={img_src} alt={img_alt} className="font-very-super-small"/>
                    </div>
                    <div className={`cont ${is_send ? `${bg_color} right` : 'app-theme left'}`}>
                        <span className={`fw-500 font-very-small ${is_send ? 'app-bg-text-color  ' : 'app-text-color'}`}>{text} {is_loading_text && <span className={`fw-500 font-small anim-icon ${is_send ? 'app-bg-text-color  ' : 'app-text-color '}`}>{msgTypeAnim('....')}</span>}</span>
                        {is_send && (
                            <div className={`icon-cont ${is_send ? 'right' : 'left'}`}>
                                {is_loading ? (
                                    <span className="far fa-circle font-very-super-small" style={{color:'#555'}}></span>
                                ) : (
                                    <>
                                    {is_error ? (
                                        <>
                                        <span className="far fa-circle font-very-super-small" style={{color:'red'}}></span>
                                        </>
                                    ) : (
                                        <>
                                        {is_seen ? (
                                            <span className="fas fa-check-double font-super-small" style={{color:'rgb(58, 95, 243)'}}></span>
                                        ) : (
                                            <span className="fas fa-check font-super-small" style={{color:'#888'}}></span>
                                        )}
                                        </>
                                    )}
                                    
                                    </>
                                    
                                )}
                            </div>
                        )}
                        <div className={`icon-cont ${is_send ? 'right-time' : 'left'}`}>
                            <span className="font-very-super-small fw-500" style={{color:is_send ? '#bbb' : '#333'}}>{time}</span>
                        </div>
                    </div>
                    {btn_pallete.length > 0 && (
                        <div className={`cont disp-block ${is_send ? 'right' : 'left'}`} style={{width:'90%'}}>
                            <div className="disp-flex wrap jc-stretch btnn-wrapper">
                                {btn_pallete.map((item, i) => {
                                    return(
                                        <Fragment key={i}>
                                            <div className="disp-block " onClick={item.onClick}>
                                                <span className="font-super-small app-text-color padd-4px br border-1px-black hover-opacity">{item.name}</span>
                                            </div>
                                        </Fragment>
                                    )
                                })}
                            </div>
                        </div>
                    )}
                    
                </div>
            )}
            
        </Content>
    )
}

export default MessageUI;