import React from "react";
import { Wrapper, Content } from "./style";

export const ChatUserUI = ({img_src="", img_alt="", is_online=false, is_active=false, title="", desc="", number_message=0, is_init_status=true, onClick, is_connected=false}) => {
    return(
        <Content onClick={onClick} className={is_active ? 'app-bg-color active' : ''}>
            <div className="disp-block img-cont">
                <img className="img" src={img_src} alt={img_alt} />
                {is_init_status && (
                    <div className="stat-cont">
                        <span className={`${is_online ? 'online':'offline'}`}></span>
                    </div>
                )}
                
            </div>
            <div className="disp-block text-cont">
                <span className="font-slightly-small fw-600" style={{color:is_active ? '#eee' : 'var(--appBgColor)', letterSpacing:'.4px'}}>{title}</span>
                <div className="disp-block">
                    <span className="font-super-small fw-500" style={{color:is_active ? '#999' : '#666', letterSpacing:'.4px'}}>{desc}</span>
                </div>
            </div>
            {is_connected && (
                <div className="disp-block">
                    <span className="font-very-super-small green fw-600" style={{ letterSpacing:'.3px'}}>Connected</span>
                </div>
            )}
            <div className="disp-block align-center num-cont">
                {number_message > 0 && (
                    <span className="font-very-super-small fw-600">{number_message}</span>
                )}
                
            </div>
        </Content>
    )
}

const ChatUI = ({children}) => {
    return(
        <Wrapper className="app-theme">
            {children}
        </Wrapper>
    )
}

export default ChatUI;