import React, {useState, useEffect, useMemo, Fragment, useCallback} from "react";
import { useSearchParams, useNavigate } from "react-router-dom";


import BackDrop from "../SmallerComponents/backDrop";
import Spinner from "components/app/school/SmallerComponents/spinner";
// import Box from "components/app/school/SmallerComponents/box";
import Background from "../SmallerComponents/background";
import Notification from "components/app/school/SmallerComponents/notification";
import Flex, { FlexWrapper } from "../SmallerComponents/flex";
import Header from "../SmallerComponents/header";
import ChatUi, { ChatUserUI } from "../SmallerComponents/chatUI";
import MessageUI, { MessageUIWrapper } from "../SmallerComponents/messageUI";
import BtnUI, { BtnUIWrapper } from "../SmallerComponents/btnUI";
//import ProfileUI, { ProfileUIImage, ProfileUIWrapper, ProfileUIWrapper2 } from "../SmallerComponents/profileUI";
import { NotificationWrapper } from "components/app/school/SmallerComponents/notification/style";
import {Wrapper} from 'components/notFound/style.js';

import voskool_logo from 'images/voskool_logo.png';

import PreloaderLine from "components/preloaderLine";

import { chatServices } from "api/chatApp/services";
import { appServices } from "api/schoolApp/services";
import { BACKEND_URL } from "actions/url";

import {setParameter} from 'components/app/school/actions/url';
import { addItemToArray } from "components/app/school/actions/array-utils/Add";
//import { updateArrayWithArray } from "components/app/school/actions/array-utils/Update";
import { filterArrayByArray } from "components/app/school/actions/array-utils/Filter";
import {updateArray} from 'components/app/school/actions/array-utils/Update';
import { groupArray } from "components/app/school/actions/array-utils/Group";
import { convertDate } from "components/app/school/SmallerComponents/action/dateConverter";
import { loopDateTime } from "components/app/school/actions/array-utils/dates";
import { spreadArrayByDatetimeDay } from "components/app/school/actions/array-utils/Spread";
import { timify } from "components/app/school/actions/timify";

const Chat = ({user_id, user_type, user={}, school_id, school, assistant_id}) => {
    const initialErrorState = useMemo(() => ({data:[], title:""}), []);
    const initialSuccessState = useMemo(() => ({title:"", text:""}), []);
    const initialPaginationState = useMemo(() => ({count:0, previous:undefined, next:undefined, results:[]}), []);
    const initialMsgState = useMemo(() => ({va:null, sender:null, is_loading:true, is_sent:false, is_error:false}), []);

    const [error, setError] = useState(initialErrorState);
    const [success] = useState(initialSuccessState);
    const [warning] = useState(initialSuccessState);
    const [preState, setPreState] = useState({chat:true});

    const [accountState, setAccountState] = useState({id:0})
    const [customerLinkState, setCustomerLinkState] = useState({id:0});
    const [currPageState, setCurrPageState] = useState({page:1, max_id:0});

    const [allMessagePaged, setAllMessagePaged] = useState(initialPaginationState);
    const [allMessages, setAllMessages] = useState([]);
    const [allInitialMessages, setAllInitialMessages] = useState([]);
    const [allPreMessage, setAllPreMessage] = useState([]);
    const [allChat, setAllChat] = useState([]);
    const [allLinkedChat, setAllLinkedChat] = useState([]);

    // const [nameVal, setNameVal] = useState('');
    const [navigate, setNavigate] = useState('');
    const [msg, setMsg] = useState('');

    const [preloading, setPreloading] = useState(true);
    const [preLoadingMsg, setPreLoadingMsg] = useState(false);
    const [loadingChat, setLoadingChat] = useState(false);
    const [loadingMsg, setLoadingMsg] = useState(false);
    const [reload, setReload] = useState(true);
    const [reloadChat, setReloadChat] = useState(false);
    const [isTyping, setIsTyping] = useState(false);

    const [isConnecting, setIsConnecting] = useState(false);
    const [isInitChat, setIsInitChat] = useState(false);
    const [isFetchMessageVa, setIsFetchMessageVa] = useState(true);

    const [searchParam] = useSearchParams();
    const nav = useNavigate();

    const fetchAccount = (id, user={}, user_type="") => {
        setError({title:'', data:[]})
        setPreloading(true);
        if(user_type === "dev") {
            appServices.getUser().then((result) => {
                setAccountState(result.data);
                setPreloading(false); 
                setIsInitChat(true);
            }).catch(error => {
                setPreloading(false);
            })
            return;
        }
       
    }
    const fetchAllCustomerInNeed = (pre=true) => {
        setLoadingChat(pre);
        setError({title:'', data:[]})
        chatServices.getAllCustomerInNeed().then(res => {
            setAllChat(addItemToArray(res.data));
            setReloadChat(true);
            setPreState((prev) => {return {...prev, chat:false}});
            setLoadingChat(false);
        }).catch(e => {
            setLoadingChat(false);
            setError({data:["Internal Server Error"], title:"Chat Data Fetch Error", click_text:'Reload', handleClick: () => setReloadChat(true)});
        })
    }
    const fetchAllCustomerWithAccount = (aid) => {
        chatServices.getAllCustomerLinked(aid).then(res => {
            setAllLinkedChat(addItemToArray(res.data));
        }).catch(() => {

        })
    }
    const fetchAllMessagesVa = (id, pre=true, start_id='', p=1, size=100) => {
        setPreLoadingMsg(pre);
        setLoadingMsg(!pre);
        chatServices.getAllMessages(id, p, size, start_id).then(res => {
            setPreLoadingMsg(false);
            setLoadingMsg(false);
            setAllMessagePaged(res.data);
            setIsConnecting(false);
            if(p > 1){
                setAllMessages((prev) => {return groupArray([...prev, ...res.data.results], 'id')});
            } else{
                const results = res.data.results;
                setAllInitialMessages(results);
                if(results.length > 0){
                    setCurrPageState((prev) => { return {...prev, max_id:results[results.length-1].id}})
                }
            }
        }).catch(e => {
            setPreLoadingMsg(false);
            setLoadingMsg(false);
            setIsConnecting(false);
        })
    }
    const fetchMsg100 = (id) => {
        chatServices.getAllMessages(id, 1, 100, '', true).then(res => {
            if(res.data.results.length > 0){
                setAllInitialMessages((prev) => {return  groupArray([...res.data.results, ...prev], 'id')});
            }
        }).catch(() => {

        })
    }

    // const fetchAllMessagesVa = (id) => {
    //     setPreLoadingMsg(true);
    //     chatServices.getAllMessagesVa(id).then(res => {
    //         setPreLoadingMsg(false);
    //         setAllMessageVa(res.data);
    //     }).catch(e => {
    //         setPreLoadingMsg(false);
    //     })
    // }
    ///[^a-zA-Z0-9\s]/
    const onChange = (e) => {
        setMsg(e.target.value);
        setIsTyping(false);
        if(customerLinkState.id){
            setIsTyping(true);
        }
    }
    const onConnect = useCallback((id) => {
        setIsConnecting(true);
        setAllInitialMessages([]);
        setAllMessages([]);
        const filter = allLinkedChat.filter((it) => it.cus_uid === id && it.is_link);
        if(filter.length > 0){
            setCustomerLinkState(filter[0]);
            fetchAllMessagesVa(filter[0].cus_uid);
            return;
        }
        const filter_all = allChat.filter((it) => it.uid === id);
        if(filter_all.length > 0){
            const data = {customer:filter_all[0].id, assistant:accountState.id}
            chatServices.createCustomerLink(data).then(res => {
                setCustomerLinkState(res.data);
                setIsConnecting(false);
                fetchAllCustomerWithAccount(accountState.uid);
            }).catch(e => {
                setIsConnecting(false);
                setCustomerLinkState((prev) => { return {...prev}});
            })
            return;
        }
        
    }, [accountState, allLinkedChat, allChat])

    const onClickChat = (uid, id) => {
        fetchAllCustomerInNeed(preState.chat);
        onConnect(uid);
        if(searchParam.get('msger') !== uid){
            setNavigate(setParameter('msger', uid));
        }
    }
    const onSendMsg = () => {
        if(msg || allPreMessage.length > 0){
            let data = {...initialMsgState, message:msg, num:allPreMessage.length}
            let pre_msg = [...allPreMessage, data];
            setAllPreMessage(pre_msg);
            if(pre_msg.filter((it => it.is_error)).length > 0){
                setMsg('');
                pre_msg.forEach((it) => {
                    setTimeout(() => {
                        const dt = {va:customerLinkState.id, sender_va:accountState.id, message:it.message, is_va:true}
                        chatServices.createMessage(dt).then(res => {
                            setAllPreMessage(allPreMessage.filter((item) => item.num !== it.num));
                            setAllInitialMessages((prev) => { return groupArray([res.data, ...prev], 'id').sort((a, b) => new Date(a.created).getTime() - new Date(b.created).getTime())})
                        }).catch(() => {

                        })
                    }, 100)
                })
            }
            if(msg){
                setMsg('');
                const dt = {va:customerLinkState.id, sender_va:accountState.id, message:data.message, is_va:true}
                chatServices.createMessage(dt).then(res => {
                    setAllPreMessage(allPreMessage.filter((item) => item.num !== data.num));
                    setAllInitialMessages((prev) => { return groupArray([res.data, ...prev], 'id').sort((a, b) => new Date(a.created).getTime() - new Date(b.created).getTime())})
                    chatServices.updateVaLinked(customerLinkState.uid, {is_typing2:new Date().getTime() - (1000 * 7) }).then(res => {
                        setAccountState((prev) => { return {...prev, is_typing2:new Date().getTime() - (1000 * 7)}});
                    })
                }).catch(() => {
                    setAllPreMessage(updateArray(allPreMessage, data.num, {...data, is_error:true}));
                })
            }
        }
    }
    useEffect(() => {
        if(reload && user_id && user.id){
            fetchAccount(user_id, user, user_type);
            fetchAllCustomerInNeed(preState.chat);
            fetchAllCustomerWithAccount(user.uid);
            setReload(false); 
        }
        if(accountState.id && isFetchMessageVa){
            // fetchAllMessagesVa(accountState.uid);
            setIsFetchMessageVa(false);
        }
    }, [reload, user_id, user_type, user, accountState, preState, isFetchMessageVa, assistant_id])

    useEffect(() => {
        if(searchParam.get('msger')){
            onConnect(searchParam.get('msger'))
        }
    }, [onConnect, searchParam])

    useEffect(() => {
        if(reloadChat && accountState.id){
            const intval = setInterval(() => {
                fetchAllCustomerInNeed(preState.chat);
                fetchAllCustomerWithAccount(accountState.uid);
            }, 1000 * 30);
            return () => {
                clearInterval(intval);
            }
            
        }
    }, [reloadChat, preState, assistant_id, accountState]);

    useEffect(() => {
        if(customerLinkState.id){
            const interval = setInterval(() => {
                fetchMsg100(customerLinkState.cus_uid);
            }, 1000*8)
            return () => {
                clearInterval(interval);
            }
        }
    }, [customerLinkState])
    useEffect(() => {
        if(isTyping){
            setTimeout(() => {
                chatServices.updateVaLinked(customerLinkState.uid, {is_typing2:new Date().getTime() + (1000 * 8) }).then(res => {
                    setCustomerLinkState((prev) => { return {...prev, is_typing2:new Date().getTime() + (1000 * 8)}});
                })
                setIsTyping(false);
            }, 1000)
        }
    }, [isTyping, customerLinkState])


    useEffect(() => {
        if(navigate){
            setNavigate('');
            nav(navigate);
        }
    }, [navigate, nav]);


    const errorHandler = error.data.map((item, index) => {
        return(<Notification type="error" text={item} key={index} title={error.title} click_text={error.click_text} handleClick={error.handleClick} />)
    })
    const filter_arr = filterArrayByArray(allChat, allLinkedChat, 'id', 'customer')
    const chat_process = filter_arr.length > 0 ? (allLinkedChat.length > 0 ? true : true) : (allLinkedChat.length > 0 ? true : false);
    const all_msg = groupArray([...allInitialMessages, ...allMessages], 'id').sort((a, b) => new Date(a.created).getTime() - new Date(b.created).getTime());;

    const msgFunc = (arr=[]) => {
        if(arr.length > 0){
            const date_diff = new Date(arr[arr.length-1].created).getTime() - new Date(arr[0].created).getTime()
            const date_days = Math.floor(date_diff / (1000 * 60 * 60 * 24))+2
            const loop_date = date_days>0 ?  loopDateTime(new Date(arr[0].created).getTime(), 'day', date_days, false) : [new Date(arr[0].created).getTime()];
            const spread = spreadArrayByDatetimeDay(arr, loop_date, 'created');
            return spread;
        }
        return [];
    }
    return(
        <>
        <BackDrop>
            <Background>
                {preloading && <PreloaderLine />}
                <NotificationWrapper>
                    {error.title && (errorHandler)}
                    {warning.id && (<Notification type="warning" timer={1000 * 60 * 60 * 2} text={warning.text} title={warning.title} click_text={warning.click_text} handleClick={warning.handleClick} />)}
                    {success.text && (<Notification type="success" timer={1000 * 30} text={success.text} title={success.title} click_text={success.click_text} handleClick={success.handleClick} />)}
                </NotificationWrapper>  
                {isInitChat ? (
                    <FlexWrapper>
                    <Flex classname="flex-25 app-body-color">
                        <Header outer_classname="min-h-120px app-bg-color">
                            <div className="disp-flex wrap jc-sb">
                                <div className="disp-block">
                                    <span className="font-med fw-700 text-upper" style={{color:'#888'}}><i className="fas fa-times"></i> </span>
                                </div>
                                {/* <div className="disp-block">
                                    <i className="fas fa-user font-super-big"></i>
                                </div> */}
                            </div>
                            <div className="disp-block align-center">
                                <div className="disp-block">
                                    <span className="font-small fw-600" style={{color:'#ddd'}}>Chats</span>
                                </div>
                                
                            </div>
                            <div className="disp-block align-center">
                                <input type="text" name="" className="font-very-small form-control" style={{background:'transparent', border:'none', borderBottom:'1px solid #666', color:'#ddd'}} placeholder="Search...." />
                            </div>
                        </Header>
                        <ChatUi>
                            {/* <ChatUserUI onClick={() => {setNavigate(setParameter('msger', 'virtual_assistant'))}} title="VOMA (Virtual Assistant)" desc="Need help?, let's chat!" is_init_status={false} img_src={voskool_logo} alt="Assistant" is_active={searchParam.get('msger') === "virtual_assistant" ? true : false} /> */}
                            {chat_process ? (
                                <>
                                {allLinkedChat.map((item, i) => {
                                    const full_name = `${item.last_name} ${item.first_name}`;
                                    const is_online = item.is_online >= new Date().getTime() ? true : false
                                    return(
                                        <Fragment key={i}>
                                            <ChatUserUI number_message={item.tot_unseen_msg2} onClick={() => onClickChat(item.cus_uid, item.customer)} is_connected={item.is_link} title={full_name.length > 15 ? `${full_name.slice(0, 15)}...` : full_name} desc={!item.about ? 'Hey, I am using VoChat' : item.about} img_src={`${BACKEND_URL}${item.profile_pic}`} is_active={searchParam.get('msger') === item.cus_uid ? true : false} is_online={is_online} />
                                        </Fragment>
                                    )
                                })}
                                {filter_arr.map((item, i) => {
                                    const full_name = `${item.last_name} ${item.first_name}`;
                                    const is_online = item.is_online >= new Date().getTime() ? true : false
                                    return(
                                        <Fragment key={i}>
                                            <ChatUserUI onClick={() => onClickChat(item.uid, item.id)} is_connected={item.is_link} title={full_name.length > 15 ? `${full_name.slice(0, 15)}...` : full_name} desc={!item.about ? 'Hey, I am using VoChat' : item.about} img_src={`${BACKEND_URL}${item.profile_pic}`} is_active={searchParam.get('msger') === item.uid ? true : false} is_online={is_online} />
                                        </Fragment>
                                    )
                                })}
                                </>
                            ) : (
                                <>
                                    <Wrapper>
                                        <h5 style={{color:'#666'}}>No customer found</h5>
                                    </Wrapper>
                                </>
                            )}
                            
                            
                        </ChatUi>
                        
                    </Flex>
                    <Flex classname="flex-50" >
                        {searchParam.get('msger') ? (
                            <>
                            {isConnecting ? (
                                <>
                                <Wrapper>
                                    <h5 style={{color:'#666'}}>Connecting..., please wait</h5>
                                </Wrapper>
                                </>
                            ) : (
                                <>
                                {searchParam.get('msger') === customerLinkState.cus_uid && (
                                    <>
                                    <Header outer_classname="min-h-30px app-theme shadow" is_fixed>
                                        <div className="disp-flex wrap jc-sb">
                                            <div className="disp-flex wrap jc-stretch">
                                                {/* <span className="font-med fw-700 text-upper" style={{color:'#888'}}>EMIFE </span> */}
                                                <div className="disp-block img-cont">
                                                    <img className="img" src={`${BACKEND_URL}${customerLinkState.profile_pic}`} alt={customerLinkState.first_name} />
                                                </div>
                                                <div className="disp-block" style={{marginTop:'8px', paddingLeft:'8px'}}>
                                                    <span className="font-slightly-small fw-600 app-text-color text-capitalize" style={{color:'var(--appBgColor)', letterSpacing:'.4px'}}>{customerLinkState.last_name} {customerLinkState.first_name}</span>
                                                </div>
                                                <div className="disp-block stat-cont" style={{marginTop:'12px', paddingLeft:'8px'}}>
                                                    <span className={`circle ${customerLinkState.is_online ? 'online' : 'offline'}`}></span>
                                                </div>
                                            </div>
                                            {/* <div className="disp-block">
                                                <div className="disp-flex wrap jc-stretch">
                                                    <div className="disp-block" style={{marginTop:'8px'}}>
                                                        <i className="fas fa-angle-right font-med" style={{color:'#666'}}></i>
                                                    </div>
                                                </div>
                                            </div> */}
                                            
                                        </div>
                                        
                                    </Header>
                                    <MessageUIWrapper outer_classname="app-body-color">
                                        {preLoadingMsg && <Spinner />}
                                        {loadingMsg && <Spinner />}
                                        {msgFunc(all_msg).map((it, i) => {
                                            return(
                                                <Fragment key={i}>
                                                    <div className="header disp-block align-center" style={{marginTop:'10px', marginBottom:'10px'}}>
                                                        <span className="font-super-small text-capitalize fw-600" style={{color:'#666'}}>{convertDate(it.time, 'ddMMyyyy')}</span>
                                                    </div>
                                                    {it.arr.map((item, j) => {
                                                        const img_src = item.sender_va ? voskool_logo : `${BACKEND_URL}${customerLinkState.profile_pic}`;
                                                        const is_send = item.sender_va ? true : false;
                                                        return(
                                                            <Fragment key={j}>
                                                                <MessageUI img_src={`${img_src}`} is_send={is_send} bg_color="app-bg-color" text={item.message} is_seen={item.is_seen}  time={timify(convertDate(item.created, 'hh:mm:ss'))}/>
                                                            </Fragment>
                                                        )
                                                    })}
                                                    
                                                </Fragment>
                                            )
                                        })}
                                        {loadingMsg && <Spinner />}
                                        {customerLinkState.is_typing1 > new Date().getTime() && (
                                            <MessageUI img_src={`${BACKEND_URL}${accountState.profile_pic}`} is_send={false} bg_color="app-bg-color" is_typing={true}   />
                                        )}
                                        <div className="header disp-block align-center" style={{marginTop:'20px', marginBottom:'5px'}}>
                                            <span className="font-super-small text-capitalize fw-600 green">Connected</span>
                                        </div>
                                    </MessageUIWrapper>
                                    <BtnUIWrapper outer_classname="min-h-15vh app-bg-color shadow b-r-b-l-20px b-r-b-r-20px y-10px">
                                        <BtnUI value={msg} onChange={e => onChange(e)} onSubmit={() => onSendMsg()} />
                                    </BtnUIWrapper>
                                    </>
                                )}
                                </>
                            )}
                            
                            
                            </>
                        ) : (
                            <>
                            <Wrapper>
                                <h5 style={{color:'#666'}}>Hello, Tap on a chat, to start message</h5>
                            </Wrapper>
                            </>
                        )}
                        
                        
                    </Flex>
                    <Flex classname="flex-25-m-none app-theme">
                        {/* <ProfileUIWrapper>
                            <ProfileUIImage src="" alt="User"/>
                            <ProfileUIWrapper2>
                                <ProfileUI classname="red" icon="fas fa-user-minus" title="Remove Friend" />
                            </ProfileUIWrapper2>
                        </ProfileUIWrapper> */}
                        <Wrapper>
                            <h5 style={{color:'#666'}}>Profile ...</h5>
                        </Wrapper>
                    </Flex>

                    {!searchParam.get('msger') && (
                        <Flex classname="flex-mobile app-body-color">
                            <Header outer_classname="min-h-120px app-bg-color">
                                <div className="disp-flex wrap jc-sb">
                                    <div className="disp-block">
                                        <span className="font-med fw-700 text-upper" style={{color:'#888'}}>EMIFE </span>
                                    </div>
                                    {/* <div className="disp-block">
                                        <i className="fas fa-user font-super-big"></i>
                                    </div> */}
                                </div>
                                <div className="disp-block align-center">
                                    <div className="disp-block">
                                        <span className="font-small fw-600" style={{color:'#ddd'}}>Chats</span>
                                    </div>
                                    
                                </div>
                                <div className="disp-block align-center">
                                    <input type="text" name="" className="font-very-small form-control" style={{background:'transparent', border:'none', borderBottom:'1px solid #666', color:'#ddd'}} placeholder="Search...." />
                                </div>
                            </Header>
                            <ChatUi>
                                {/* <ChatUserUI onClick={() => {setNavigate(setParameter('msger', 'virtual_assistant'))}} title="VOMA (Virtual Assistant)" desc="Need help?, let's chat!" is_init_status={false} img_src={voskool_logo} alt="Assistant" is_active={searchParam.get('msger') === "virtual_assistant" ? true : false} /> */}
                                {chat_process ? (
                                    <>
                                    {allLinkedChat.map((item, i) => {
                                        const full_name = `${item.last_name} ${item.first_name}`;
                                        const is_online = item.is_online >= new Date().getTime() ? true : false
                                        return(
                                            <Fragment key={i}>
                                                <ChatUserUI onClick={() => onClickChat(item.cus_uid, item.customer)} is_connected={item.is_link} title={full_name.length > 15 ? `${full_name.slice(0, 15)}...` : full_name} desc={!item.about ? 'Hey, I am using VoChat' : item.about} img_src={`${BACKEND_URL}${item.profile_pic}`} is_active={searchParam.get('msger') === item.cus_uid ? true : false} is_online={is_online} />
                                            </Fragment>
                                        )
                                    })}
                                    {filter_arr.map((item, i) => {
                                        const full_name = `${item.last_name} ${item.first_name}`;
                                        const is_online = item.is_online >= new Date().getTime() ? true : false
                                        return(
                                            <Fragment key={i}>
                                                <ChatUserUI onClick={() => onClickChat(item.uid, item.id)} is_connected={item.is_link} title={full_name.length > 15 ? `${full_name.slice(0, 15)}...` : full_name} desc={!item.about ? 'Hey, I am using VoChat' : item.about} img_src={`${BACKEND_URL}${item.profile_pic}`} is_active={searchParam.get('msger') === item.uid ? true : false} is_online={is_online} />
                                            </Fragment>
                                        )
                                    })}
                                    </>
                                ) : (
                                    <>
                                        <Wrapper>
                                            <h5 style={{color:'#666'}}>No customer found</h5>
                                        </Wrapper>
                                    </>
                                )}
                                
                                
                            </ChatUi>
                            
                        </Flex>
                    )}
                    {searchParam.get('msger') && (
                        <Flex classname="flex-mobile" >
                            {searchParam.get('msger') ? (
                                <>
                                {isConnecting ? (
                                    <>
                                    <Wrapper>
                                        <h5 style={{color:'#666'}}>Connecting..., please wait</h5>
                                    </Wrapper>
                                    </>
                                ) : (
                                    <>
                                    {searchParam.get('msger') === customerLinkState.cus_uid && (
                                        <>
                                        <Header outer_classname="min-h-30px app-theme shadow" is_fixed>
                                            <div className="disp-flex wrap jc-sb">
                                                <div className="disp-flex wrap jc-stretch">
                                                    {/* <span className="font-med fw-700 text-upper" style={{color:'#888'}}>EMIFE </span> */}
                                                    <div className="disp-block img-cont">
                                                        <img className="img" src={`${BACKEND_URL}${customerLinkState.profile_pic}`} alt={customerLinkState.first_name} />
                                                    </div>
                                                    <div className="disp-block" style={{marginTop:'8px', paddingLeft:'8px'}}>
                                                        <span className="font-slightly-small fw-600 app-text-color text-capitalize" style={{color:'var(--appBgColor)', letterSpacing:'.4px'}}>{customerLinkState.last_name} {customerLinkState.first_name}</span>
                                                    </div>
                                                    <div className="disp-block stat-cont" style={{marginTop:'12px', paddingLeft:'8px'}}>
                                                        <span className={`circle ${customerLinkState.is_online ? 'online' : 'offline'}`}></span>
                                                    </div>
                                                </div>
                                                {/* <div className="disp-block">
                                                    <div className="disp-flex wrap jc-stretch">
                                                        <div className="disp-block" style={{marginTop:'8px'}}>
                                                            <i className="fas fa-angle-right font-med" style={{color:'#666'}}></i>
                                                        </div>
                                                    </div>
                                                </div> */}
                                                
                                            </div>
                                            
                                        </Header>
                                        <MessageUIWrapper outer_classname="app-body-color">
                                            {preLoadingMsg && <Spinner />}
                                            {loadingMsg && <Spinner />}
                                            {msgFunc(all_msg).map((it, i) => {
                                                return(
                                                    <Fragment key={i}>
                                                        <div className="header disp-block align-center" style={{marginTop:'10px', marginBottom:'10px'}}>
                                                            <span className="font-super-small text-capitalize fw-600" style={{color:'#666'}}>{convertDate(it.time, 'ddMMyyyy')}</span>
                                                        </div>
                                                        {it.arr.map((item, j) => {
                                                            const img_src = item.sender_va ? voskool_logo : `${BACKEND_URL}${customerLinkState.profile_pic}`;
                                                            const is_send = item.sender_va ? true : false;
                                                            return(
                                                                <Fragment key={j}>
                                                                    <MessageUI img_src={`${img_src}`} is_send={is_send} bg_color="app-bg-color" text={item.message} is_seen={item.is_seen}  time={timify(convertDate(item.created, 'hh:mm:ss'))}/>
                                                                </Fragment>
                                                            )
                                                        })}
                                                        
                                                    </Fragment>
                                                )
                                            })}
                                            {loadingMsg && <Spinner />}
                                            {customerLinkState.is_typing1 > new Date().getTime() && (
                                                <MessageUI img_src={`${BACKEND_URL}${accountState.profile_pic}`} is_send={false} bg_color="app-bg-color" is_typing={true}   />
                                            )}
                                            <div className="header disp-block align-center" style={{marginTop:'20px', marginBottom:'5px'}}>
                                                <span className="font-super-small text-capitalize fw-600 green">Connected</span>
                                            </div>
                                        </MessageUIWrapper>
                                        <BtnUIWrapper outer_classname="min-h-15vh app-bg-color shadow b-r-b-l-20px b-r-b-r-20px y-10px">
                                            <BtnUI value={msg} onChange={e => onChange(e)} onSubmit={() => onSendMsg()} />
                                        </BtnUIWrapper>
                                        </>
                                    )}
                                    </>
                                )}
                                
                                
                                </>
                            ) : (
                                <>
                                <Wrapper>
                                    <h5 style={{color:'#666'}}>Hello, Tap on a chat, to start message</h5>
                                </Wrapper>
                                </>
                            )}
                            
                            
                        </Flex>
                    )}
                </FlexWrapper>
                ) : (
                    <>
                    
                    </>
                )}
                
            </Background>
        </BackDrop>
        </>
    )
}

export default Chat;