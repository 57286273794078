import http from "api/http_setup";

const chat_url = 'chat'
export const chatServices = {
    getAllCustomerInNeed: () => {
        return http.get(`${chat_url}/va/customer_search/all/${new Date().getTime()}`);
    },
    getAllCustomerLinked: (aid=0) => {
        return http.get(`${chat_url}/va/customer_linked/all/${aid}/${new Date().getTime()}`);
    },
    createMessage: (data={}) => {
        return http.post(`${chat_url}/va/message/create`, data);
    },
    getAllMessages: (id=0, p=1, size=100, start_id='', is_seen='') => {
        return http.get(`${chat_url}/va/message/all/${id}?p=${p}&size=${size}&start_id=${start_id}&iss=${is_seen}`);
    },
    updateVaLinked: (id=0, data={}) => {
        return http.put(`${chat_url}/va/account/assistant/update/${id}`, data);
    },
    getCustomerLinked: (id=0) => {
        return http.get(`${chat_url}/va/customer_linked/get/${id}`);
    },
    createCustomerLink: (data={}) => {
        return http.post(`${chat_url}/va/customer/link/create`, data);
    },
    // getAccount: (id=0) => {
    //     return http.get(`${chat_url}/account/main/get/${id}`);
    // },
}