import React from "react";
import { OuterWrapper } from "../header/style";
import { Wrapper, Content } from './style';

export const BtnUIWrapper = ({children, outer_classname="", is_fixed=false}) => {
    return(
        <OuterWrapper style={{position:'relative'}}>
            <Wrapper className={`${outer_classname} ${is_fixed ? 'pos-fixed' : ''}`}>
                {children}
            </Wrapper>
        </OuterWrapper>
    )
}

const BtnUI = ({name="msg", value="", onChange, onSubmit}) => {
    return(
        <Content>
            <div className="disp-block inp-cont">
                <textarea type="text" placeholder="Message..."  onChange={onChange} name={name} value={value} className="inp font-very-small" />
            </div>
            <div className="disp-block btn-wrapper">
                <div className="btn-cont">
                    {/* <div className="disp-block padd-r-4px">
                        <i className="far fa-user font-slightly-small" style={{color:'#aaa'}}></i>
                    </div> */}
                    <div className="disp-block padd-l-4px">
                        <i className="fas fa-paper-plane font-slightly-small app-bg-text-color" onClick={onSubmit}></i>
                    </div>
                </div>
                
            </div>
        </Content>
    )
}

export default BtnUI;